<template>
  <div class="g19">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div class="spread-wrapper">
      <my-spread
        v-if="defaultDataList.length"
        ref="mySpread"
        :spread-info="spreadInfo"
        :default-data-list="defaultDataList"
        >
      </my-spread>
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import auth from '@/common/auth'
import G19SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G19SpreadInfo'
import g19Service from '@/services/g19Service'
import MySpread from '@/components/Spread/MySpreadOld'
import G19Model from '@/model/G19Model'
// import utility from '@/common/utility'
import { mapGetters } from 'vuex'

export default {
  name: 'g19',
  components: {
    MySpread,
    MySearchBar
  },
  data () {
    return {
      defaultDataList: [],
      spreadInfo: G19SpreadInfo
    }
  },
  computed: {
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      month: 'getMonth',
      date: 'getDate',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      // 检查是否有错误
      this.$refs.mySpread.validate()
      console.log(this.$refs.mySpread.getData())
      if (this.$refs.mySpread.errorKeys.length === 0) {
        this.$myLoading()
        let data = this.$refs.mySpread.getData().map((item, index) => {
          item.planStatExaminationGuid = this.planStatExaminationGuid
          item.materialListGuid = this.defaultDataList[index].materialListGuid
          item.g28Guid = this.defaultDataList[index].g28Guid
          item.tenderGuid = this.tenderGuid
          item.year = this.year
          item.month = this.month
          item.date = this.date
          return item
        }).filter(item => item.currentDateCompleteNum !== '').map(item => {
          item.currentMonthNum = parseFloat(item.currentMonthNum)
          item.currentDateCompleteNum = parseFloat(item.currentDateCompleteNum)
          item.currentMonthCompleteNum = parseFloat(item.currentMonthCompleteNum)
          item.totalCompleteNum = parseFloat(item.totalCompleteNum)
          return new G19Model(item)
        })
        console.log('transferData', data)
        g19Service.batchAdd(data)
          .then(res => {
            this.$loading().close()
            this.$message({
              type: 'success',
              message: '保存成功！'
            })
            this.$router.push('/G19Index')
          })
          .catch(err => {
            console.log(err)
            this.$loading().close()
            this.$message({
              type: 'error',
              message: '保存失败，请重试！'
            })
          })
      } else {
        this.$message({
          type: 'error',
          message: '校验失败，请检查您的输入！'
        })
      }
    },
    getDefaultData () {
      g19Service.getDefaultData(this.tenderGuid, this.planStatExaminationGuid, this.year, this.month, this.date)
        .then(res => {
          if (res.data.code === 1) {
            this.defaultDataList = res.data.data.map((item, i) => {
              item.serialNo = i + 1
              return item
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败，请重试！'
          })
        })
    }
  },
  created () {
    this.getDefaultData()
  }
}
</script>

<style scoped lang="scss">
.g19 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-wrapper {
    flex: 1;
  }
}
</style>
