import utility from '@/common/utility'

const G19SpreadInfo = {
  sheetName: 'G19',
  columns: [
    {
      label: '序号',
      dataKey: 'serialNo',
      width: '50'
    },
    {
      label: '工程名称',
      dataKey: 'materialListName',
      width: '100',
      children: [
        {
          label: '工程类别',
          dataKey: 'parentEngineeringListName',
          width: '150'
        },
        {
          label: '工程名称',
          dataKey: 'materialListName',
          width: '200'
        }
      ]
    },
    {
      label: '单位',
      dataKey: 'unit',
      width: '60'
    },
    {
      label: '合同量',
      dataKey: 'num',
      width: '100'
    },
    {
      label: '本月计划',
      dataKey: 'currentMonthNum',
      width: '90'
    },
    {
      label: '本日完成',
      dataKey: 'currentDateCompleteNum',
      editable: true,
      validator (row) {
        if (row.currentDateCompleteNum !== undefined && row.currentDateCompleteNum !== null && row.currentDateCompleteNum !== '') {
          if (isNaN(row.currentDateCompleteNum)) {
            return false
          }
        }
        return true
      },
      width: '90'
    },
    {
      label: '本月累计',
      dataKey: 'currentMonthCompleteNum',
      validator (row) {
        if (row.currentMonthCompleteNum !== undefined && row.currentMonthCompleteNum !== null && row.currentMonthCompleteNum !== '') {
          if (isNaN(row.currentMonthCompleteNum)) {
            return false
          }
        }
        return true
      },
      formula (row, defaultDataList) {
        return utility.floatAdd(row.currentDateCompleteNum, defaultDataList[row.index].monthSum).toFixed(2)
      },
      width: '90'
    },
    {
      label: '全部累计',
      dataKey: 'totalCompleteNum',
      validator (row) {
        if (row.totalCompleteNum !== undefined && row.totalCompleteNum !== null && row.totalCompleteNum !== '') {
          if (isNaN(row.totalCompleteNum)) {
            return false
          }
        }
        return true
      },
      formula (row, defaultDataList) {
        return utility.floatAdd(row.currentDateCompleteNum, defaultDataList[row.index].totalSum).toFixed(2)
      },
      width: '90'
    }
  ]
}

export default G19SpreadInfo
